import React from 'react'
import { graphql } from 'gatsby'

import Head from "../components/head"
import Layout from '../components/layout'

export const query = graphql`
  query($slug: Date!) {
    markdownRemark(
      fields: {
        slug: {
          eq: $slug
        }
      }
    ) {
      html
      frontmatter {
        title
        speaker
        where
        eventdate_day: eventdate(formatString: "ddd")
        eventdate_year: eventdate(formatString: "YYYY")
        eventdate_date: eventdate(formatString: "DD MMM")
        eventtime
        admission
      }
    }
  }
`

const EventPage = (props) => {

  // extract the contents from data
  const { markdownRemark } = props.data;
  const { frontmatter, html } = markdownRemark;

  // return the component layout
  return (
    <Layout title={ `Event: ${ frontmatter.title }` }>
      <Head title={ `Event: ${ frontmatter.title }` } description="Event details"/>
      <div className="content is-hidden-desktop">
        <p className="is-size-6">
          { frontmatter.eventtime }, { frontmatter.eventdate_day}, { frontmatter.eventdate_date } { frontmatter.eventdate_year }<br />
          { frontmatter.where }
        </p>
      </div>
      <div className="columns is-desktop">
        <div className="column is-full-mobile is-two-thirds-desktop">
          <p className="subtitle is-4">{ frontmatter.speaker }</p>
          <div className="content">
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
          </div>
        </div>
        <div className="column is-one-third-desktop is-hidden-touch">
          <div className="box">
            <div className="content">
              <p className="is-size-6">
              <span className="is-size-6">{ frontmatter.eventtime }</span><br />
              { frontmatter.eventdate_day}, { frontmatter.eventdate_date }, { frontmatter.eventdate_year }<br />
              </p>
            </div>
            <div className="content">
              <p className="is-pre">{ frontmatter.where }</p>
              <p className="is-pre">{ frontmatter.admission }</p>
            </div>
          </div>
        </div>
      </div>
      <div className="content is-hidden-desktop">
        <p className="is-pre">{ frontmatter.admission }</p>
      </div>
    </Layout>
  );
}
export default EventPage;